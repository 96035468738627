import "./Intro.css";
import ok from "../images/okIcon.svg";

function Intro({ question, onSubmitClick }) {
  return (
    <div className="Intro">
      {question.intro && <div className="Intro-intro">{question.intro}</div>}

      <form className="Intro-form">
        {question.button && (
          <div className="Intro-submit">
            <button className="Intro-submit__button" onClick={onSubmitClick}>
              {question.button}{" "}
              <img className="Intro-quest__ok" src={ok} alt="" />
            </button>
            <p className="Intro-submit__text">
              pulsa <strong>Enter</strong>
            </p>
          </div>
        )}
      </form>
    </div>
  );
}

export default Intro;
