import "./Trabaja.css";
import arrow from "../images/arrow.svg";
import { useState } from "react";
import { processForm } from "../../api/endpoints";
import Spinner from "../../loader/spinner";

function Trabaja({ question, onSubmitClick }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);
        processForm(selectedFile, null)
            .then((res) => {
                console.log(res)
                setLoading(false);
                onSubmitClick();
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    } else {
      onSubmitClick();
    }
  };
  return (
    <div className="Trabaja">
      <div className="Trabaja-quest">
        {question.number && (
          <p className="Trabaja-quest__number">
            {question.number}
            <img className="Trabaja-quest__arrow" src={arrow} alt="" />
          </p>
        )}
        {question.title && (
          <p className="Trabaja-quest__title">{question.title}</p>
        )}
      </div>
      <div className="Trabaja-subtitle">
        {question.text && (
          <p className="Text-subtitle__text">{question.subtext}</p>
        )}
        {question.subtext && (
          <p className="Text-subtitle__subtext">{question.subtext}</p>
        )}
      </div>
      <div className="Trabaja-form">
        <div className="Trabaja-input">
          <div className="Trabaja-input__container">
            <input
              className="Trabaja-input__input"
              type="file"
              placeholder="Escribe aquí tu respuesta..."
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
          </div>
        </div>
        <span className="Trabaja-file">
          {selectedFile ? selectedFile.name : null}
        </span>

        {question.button && (
          <div className="Trabaja-submit">
            <button
              onClick={handleUpload}
              className="Trabaja-submit__button"
              disabled={loading}
            >
              {loading ? (
                <div className="Trabaja-spinner"> {<Spinner />}</div>
              ) : (
                question.button
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Trabaja;
