
import Processor from "./logic/processor"
import "./App.css"

function App() {
  return (
    <div className="App">
     <Processor />
    </div>
  );
}

export default App;
