import "./ProductSearch.css";
import arrow from "../images/arrow.svg";
import ok from "../images/okIcon.svg";
import dropdownArrow from "../images/dropdownArrow.svg";
import { useState } from "react";
import { useEffect } from "react";

function ProductSearch({ question }) {
  const [products, setProducts] = useState(null);
  const [inputValue, setInputValue] = useState("");
  // const token = process.env.REACT_APP_TOKEN_STOREFRONT;
  const [productsResponse, setProductsResponse] = useState();

  function retrieveProducts() {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://assets.pdpaola.com/products/feeds/es.json", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result) {
          setProductsResponse(JSON.parse(result));
          // console.log(result)
        } else {
          setProductsResponse([]);
        }
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    if (!productsResponse) {
      retrieveProducts();
    }
  }, []);

  useEffect(() => {
    if (productsResponse && inputValue.length > 2) {
      setProducts(
        productsResponse.filter((p) =>
          p.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  }, [inputValue, productsResponse]);

  const onSubmitClick = () => {
    const parsedResponses = sessionStorage.responses
      ? JSON.parse(sessionStorage.responses)
      : { questions: [] };

    const responsesArray = parsedResponses.questions
      ? parsedResponses.questions
      : [];

    const p = productsResponse.find((p) => p.name == inputValue);
    const newJson = {
      type: question.type,
      question: question.title,
      answer: `${document.querySelector("input").value} ${p?.external_id}`,
    };
    responsesArray.push(newJson);
    sessionStorage.responses = JSON.stringify({
      questions: responsesArray,
    });
    const lastViews = JSON.parse(sessionStorage.lastViews);
    lastViews.lastViews.push(question.id);
    sessionStorage.lastViews = JSON.stringify(lastViews);
    sessionStorage.currentView = question.next;
  };

  const onOptionClick = (e) => {
    e.preventDefault();
    const productTitle = e.currentTarget.innerText;
    setInputValue(productTitle);
    document.querySelector("input").value = productTitle;
  };

  const inputOnChange = () => {
    setInputValue(document.querySelector("input").value);
  };

  return (
    <div className="ProductSearch">
      <div className="ProductSearch-quest">
        <p className="ProductSearch-quest__number">
          {question.number}
          <img className="ProductSearch-quest__arrow" src={arrow} alt="" />
        </p>
        <p className="ProductSearch-quest__title">{question.title}</p>
      </div>
      <div className="ProductSearch-subtitle">
        <p className="ProductSearch-subtitle__text"></p>
      </div>
      <form className="ProductSearch-form">
        <div className="ProductSearch-input">
          <input
            onKeyUp={inputOnChange}
            className="ProductSearch-input__input"
            type="text"
            placeholder="Escribe o selecciona una opción"
          />

          {products && inputValue.length > 2 && (
            <div className="ProductSearch-options">
              {products.map((product, key) => {
                return (
                  <button
                    key={key}
                    product
                    className={"ProductSearch-options__option"}
                    onClick={onOptionClick}
                  >
                    {product.images && (
                      <img
                        className="ProductSearch-option__image"
                        src={product.images[0]}
                        alt=""
                      />
                    )}
                    {product.name}
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <div className="ProductSearch-submit">
          <button
            onClick={onSubmitClick}
            className="ProductSearch-submit__button"
          >
            Aceptar <img className="ProductSearch-quest__ok" src={ok} alt="" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductSearch;
