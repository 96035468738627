import "./Thanks.css";

function Thanks({ question }) {
  function resetStorage() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div className="Thanks">
      {question.title && <div className="Thanks-title">{question.title}</div>}

      {question.button && (
        <div className="Thanks-submit">
          <button className="Thanks-submit__button" onClick={resetStorage}>
            {question.button}{" "}
          </button>
        </div>
      )}
    </div>
  );
}

export default Thanks;
