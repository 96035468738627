import "./TextInput.css";
import arrow from "../images/arrow.svg";
import ok from "../images/okIcon.svg";
import { useState } from "react";
import { processForm } from "../../api/endpoints";
import Spinner from "../../loader/spinner";

function TextInput({ question, onSubmitClick }) {
  const [text, setText] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const hasError = (e) => {
    if (text.trim() === "" || !text) {
      //   e.preventDefault();
      setError("* Campo vacío");

      return true;
    } else {
      if (question.type === "mail") {
        const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const condition = text.match(mailRegex);
        if (!condition) {
          //   e.preventDefault();
          setError("* Email inválido");
          return true;
        }
        setError(null);
        return false;
      } else if (question.type === "nReferencia") {
        // No utilizamos regex porque es numero de referencia de pdpaola totalmente variable
        const condition = text.length > 7 && text.length < 10; // PDPxxxxxx (9) / GExxxxxx (8)
        if (!condition) {
          //   e.preventDefault();
          setError("* Número de referencia incorrecto");
          return true;
        }
        setError(null);
        return false;
      }
      setError(null);
      return false;
    }
  };

  const handleProcessForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    processForm(null, text).then((res)=> {
      console.log(res)
                   setLoading(false);

      onSubmitClick();
    }).catch((err) => {
      console.error(err)
                   setLoading(false);

    })
  };

  const handleClick = () => {
    setText("");
    onSubmitClick();
  };
  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="TextInput">
      {(question.number || question.title) && (
        <div className="TextInput-quest">
          {question.number && (
            <p className="TextInput-quest__number">
              {question.number}
              <img className="TextInput-quest__arrow" src={arrow} alt="" />
            </p>
          )}
          {question.title && (
            <p className="TextInput-quest__title">{question.title}</p>
          )}
        </div>
      )}
      {(question.text || question.subtext) && (
        <div className="TextInput-subtitle">
          {question.text && (
            <p className="TextInput-subtitle__text">{question.text}</p>
          )}
          {question.subtext && (
            <p className="TextInput-subtitle__subtext">{question.subtext}</p>
          )}
        </div>
      )}
      <form className="TextInput-form">
        <div className="TextInput-input">
          <input
            className={
              error
                ? "TextInput-input__input input-error"
                : "TextInput-input__input"
            }
            type="text"
            autoFocus
            placeholder="Escribe aquí tu respuesta..."
            onChange={(e) => handleChange(e)}
          />
          {error ? <p className="TextInput-input__error">{error}</p> : null}
        </div>
        {question.button && (
          <div className="TextInput-submit">
            <button
              className="TextInput-submit__button"
              onClick={(e) =>
                question.exception
                  ? handleProcessForm(e)
                  : !hasError(e)
                  ? handleClick()
                  : e.preventDefault()
              }
              disabled={loading}
            >
              {loading ? (
                <div className="Selector-spinner"> {<Spinner />}</div>
              ) : (
                <>
                  {question.button}{" "}
                  <img className="TextInput-quest__ok" src={ok} alt="" />
                </>
              )}
            </button>
            <p className="TextInput-submit__text">
              pulsa <strong>Enter</strong>
            </p>
          </div>
        )}
      </form>
    </div>
  );
}

export default TextInput;
