import "./Selector.css";
import arrow from "../images/arrow.svg";
import ok from "../images/okIcon.svg";
import { useState } from "react";
import { processForm } from "../../api/endpoints";
import Spinner from "../../loader/spinner";

function Selector({ question, onSubmitClick }) {
  const options = question.options;
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const toggleSelected = (value) => {
    if (selected && selected.option_value === value.option_value) {
      setSelected();
    } else {
      setSelected(value);
    }
  };

  const saveSelectorInfo = async () => {
    // console.log("het", selected);
    if (selected && selected.option_value) {
      const parsedResponses = sessionStorage.responses
        ? JSON.parse(sessionStorage.responses)
        : { questions: [] };

      const responsesArray = parsedResponses.questions
        ? parsedResponses.questions
        : [];
      const newJson = {
        type: question.type,
        question: question.title,
        answer: selected.option_value,
      };
      responsesArray.push(newJson);
      sessionStorage.responses = JSON.stringify({
        questions: responsesArray,
      });
      // sessionStorage.currentView = selected.next;
      // console.log(selected.next);
      if (selected.exception) {
        setLoading(true);
        processForm(null, null)
           .then(() => {
             setLoading(false);
             onSubmitClick();
           })
           .catch((err) => console.error(err));
        setLoading(false);
      } else {
        onSubmitClick();
      }
    }
  };

  const onClickOption = (e, option) => {
    // e.preventDefault();
    toggleSelected(option);
    sessionStorage.nextView = option.next;
  };

  return (
    <div className="Selector">
      {(question.number || question.title) && (
        <div className="Selector-quest">
          {question.number && (
            <p className="Selector-quest__number">
              {question.number}
              <img className="Selector-quest__arrow" src={arrow} alt="" />
            </p>
          )}
          {question.title && (
            <p className="Selector-quest__title">{question.title}</p>
          )}
        </div>
      )}
      <div action="" className="Selector-form">
        {question.options && (
          <div className="Selector-options">
            {options?.map((option, key) => {
              return (
                <button
                  key={key}
                  className={
                    selected?.option_value === option?.option_value
                      ? "Selector-options__option selected"
                      : "Selector-options__option"
                  }
                  onClick={(e) => {
                    onClickOption(e, option);
                  }}
                >
                  <div className="Selector-options__letter">
                    {option.option_name}
                  </div>
                  {option.option_value}
                  <input
                    className="Selector-options__checkbox"
                    type="checkbox"
                  />
                </button>
              );
            })}
          </div>
        )}
        {question.button && (
          <div className="Selector-submit">
            <button
              type="submit"
              className="Selector-submit__button"
              onClick={saveSelectorInfo}
              disabled={loading || !selected}
            >
              {loading ? (
                <div className="Selector-spinner"> {<Spinner />}</div>
              ) : (
                <>
                  {question.button}{" "}
                  <img className="Selector-quest__ok" src={ok} alt="" />
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Selector;
