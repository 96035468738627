export const processForm = async (selectedFile, text) => {
  if (selectedFile) {
    console.log('File...')
    return sendForm(null)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const ticketId = data.ticket?.id;

        if (ticketId) {
          return uploadFile(selectedFile)
            .then((response) => response.json())
            .then((data) => {
              const token = data?.upload?.token;

              if (token) {
                return attachFile({ token: token, ticketId: ticketId });
              }
            })
            .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));
  }else {
    return sendForm(text).then((res)=> {
      return res.json()
    }).catch((err)=> {
      console.error(err)
      return null
    });
  }
};

const sendForm = (text) => {
  const key = process.env.REACT_APP_TOKEN_GCP;
  const body = buildBody(text);
  if (body) {
    return fetch("https://zendesk3-2bchdfg6.ew.gateway.dev/sendForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": key,
      },
      body: JSON.stringify(body),
    });
  }
};

const uploadFile = (selectedFile) => {
  const key = process.env.REACT_APP_TOKEN_GCP;
  const formData = new FormData();
  formData.append("file", selectedFile, selectedFile.name);

  return fetch("https://zendesk3-2bchdfg6.ew.gateway.dev/uploadFile", {
    method: "POST",
    body: formData,
    headers: {
      "x-api-key": key,
      // 'Access-Control-Allow-Origin': '*'
      // "Content-Type": "multipart/form-data",
      // "Content-Disposition": `attachment; filename="${selectedFile.name}"`,
    },
  });
};

const attachFile = ({ token, ticketId }) => {
  const key = process.env.REACT_APP_TOKEN_GCP;
  return fetch("https://zendesk3-2bchdfg6.ew.gateway.dev/attachFile", {
    method: "POST",
    body: JSON.stringify({ token: token, ticketId: ticketId }),
    headers: {
      "x-api-key": key,
      // "Content-Type": "multipart/form-data",
      // "Content-Disposition": `attachment; filename="${selectedFile.name}"`,
    },
  });
};

const buildBody = (text) => {
  const questionsJson = sessionStorage.getItem("responses");
  if (questionsJson) {
    const questions = JSON.parse(questionsJson).questions;

    const bodyJson = {
      subject: "",
      requester: {
        name: "",
        email: "",
      },
      comment: {
        body: "",
      },
      tags: [],
      via: {
        channel: "web",
      },
    };

    bodyJson.subject = questions.find((q) => q.type === "howToHelp")?.answer;
    bodyJson.requester.name = questions.find((q) => q.type === "name")?.answer;
    bodyJson.requester.email = questions.find((q) => q.type === "mail")?.answer;

    const referenciaArray = questions.filter(
      (q) => q.type === "nReferencia" || q.type === "direccionEntrega"
    );

    bodyJson.comment.body += referenciaArray.length
      ? "Referencia: " + referenciaArray?.map((r) => r.answer) + "."
      : "";

    const recepcionArray = questions.filter(
      (q) =>
        q.type === "cancelacion" ||
        q.type === "motivoCancelacion" ||
        q.type === "comoAyudar"
    );
    bodyJson.comment.body += recepcionArray.length
      ? "Recepción: " + recepcionArray?.map((r) => r.answer) + "."
      : "";

    const prodEquivocado = questions.find(
      (q) => q.type === "productoErroneo"
    )?.answer;
    bodyJson.comment.body += prodEquivocado
      ? "P.equivocado: " + prodEquivocado
      : "";

    const prodFaltante = questions.find(
      (q) => q.type === "faltaProducto"
    )?.answer;
    bodyJson.comment.body += prodFaltante
      ? "P.faltante: " + prodFaltante
      : "";

    bodyJson.comment.body += text ? ' Ayuda: ' + text : '';

    const tagsArray = questions.filter(
      (q) => q.type === "infoNecesitada" || q.type === "queInfo"
    );
    bodyJson.tags = tagsArray.map((ta) => ta.answer);
    if (bodyJson.comment.body === ''){
      if (bodyJson.tags.includes('Trabaja con nosotros')){
        bodyJson.comment.body += "Enviado CV";
      }else {
        bodyJson.comment.body += 'No se han especificado comentarios.'
      }
    }
    console.log({'ticket': bodyJson})
    return {'ticket': bodyJson};
  }
  return null;
};
