import "../App.css";
import backArrow from "../cards/images/backArrow.svg";
import TextInput from "../cards/CardTextInput/TextInput";
import Selector from "../cards/CardSelector/Selector";
import Text from "../cards/CardText/Text";
import Trabaja from "../cards/CardTrabaja/Trabaja";
import Intro from "../cards/CardIntro/Intro";
import ProductSearch from "../cards/CardProductSearch/ProductSearch";
import Thanks from "../cards/CardThanks/Thanks";
import Questions from "../questions/questions.json";
import { useEffect } from "react";
import { useState } from "react";

function Processor() {
  const [questionIndex, setQuestionIndex] = useState(
    sessionStorage.currentView
  );
  const [current, setCurrent] = useState(Questions[questionIndex]);

  useEffect(() => {
    
    const curr = Questions.find((q)=> q.id === questionIndex)
    // console.log('Curr', curr)
    // console.log('hey', questionIndex)
    if (questionIndex) {
      setCurrent(curr);
    }
  }, [questionIndex]);

  useEffect(() => {
    if (!sessionStorage.currentView) {
      sessionStorage.currentView = 0;
      window.location.reload();
    }
    if (!sessionStorage.lastViews) {
      sessionStorage.lastViews = JSON.stringify({ lastViews: [] });
    }
  }, [questionIndex]);

  const onBackClick = () => {
    const lastViews = JSON.parse(sessionStorage.lastViews);
    const parsedResponses = JSON.parse(sessionStorage.responses);
    if (lastViews.lastViews.length > 0) {
      sessionStorage.currentView = lastViews.lastViews.pop();
      parsedResponses.questions.pop();
      sessionStorage.responses = JSON.stringify(parsedResponses);
    }
    sessionStorage.lastViews = JSON.stringify(lastViews);
    setQuestionIndex(sessionStorage.currentView);
    setCurrent(Questions[sessionStorage.currentView]);
  };

  const onSubmitClick = () => {
    // console.log(current)
    if (current) {
      const parsedResponses = sessionStorage.responses
        ? JSON.parse(sessionStorage.responses)
        : { questions: [] };

      const responsesArray = parsedResponses.questions
        ? parsedResponses.questions
        : [];

      switch (current.cardtype) {
        case "TextInput":
          const newJson = {
            type: current.type,
            question: current.title,
            answer: document.querySelector("input").value,
          };
          responsesArray.push(newJson);
          sessionStorage.responses = JSON.stringify({
            questions: responsesArray,
          });
          break;
        case "Intro":
          sessionStorage.responses = JSON.stringify({ questions: [] });
          break;
        default:
          break;
      }
      const lastViews = JSON.parse(sessionStorage.lastViews);
      lastViews.lastViews.push(current.id);
      sessionStorage.lastViews = JSON.stringify(lastViews);
      if (sessionStorage.nextView) {
        sessionStorage.currentView = sessionStorage.nextView;
        
        console.log(current)
        console.log(sessionStorage.nextView)
        
        const nextQ = Questions.find((q)=> q.id === sessionStorage.nextView)
        console.log(nextQ)
        sessionStorage.removeItem("nextView");
        setTimeout(() => {
          setCurrent(nextQ);
        }, 500);
      } else {
        current.next? sessionStorage.currentView = current.next : sessionStorage.currentView = sessionStorage.currentView;
        // console.log(current.next) 
        setTimeout(() => {
          setCurrent(current);
          setQuestionIndex(current.next);
        }, 500);
      }
    }
  };
  const getType = () => {
    // console.log("RENDER", current);
    if (current && current.cardtype) {
      switch (current.cardtype) {
        case "Selector":
          return <Selector question={current} onSubmitClick={onSubmitClick} />;
        case "Intro":
          return <Intro question={current} onSubmitClick={onSubmitClick} />;
        case "TextInput":
          return <TextInput question={current} onSubmitClick={onSubmitClick} />;
        case "ProductSearch":
          return (
            <ProductSearch question={current} onSubmitClick={onSubmitClick} />
          );
        case "Text":
          return <Text question={current} onSubmitClick={onSubmitClick} />;
        case "Trabaja":
          return <Trabaja question={current} onSubmitClick={onSubmitClick} />;
        case "Thanks":
          return <Thanks question={current} />;
        default:
          return <div>{current.cardtype}</div>;
      }
    }
  };
  if (Questions && Questions.length && current) {
    return (
      <div className="Processor">
        {JSON.parse(sessionStorage.lastViews).lastViews.length !== 0 &&
          current.cardtype !== "Thanks" && (
            <div className="back-arrow" onClick={onBackClick}>
              <img src={backArrow} alt="" />
              Ir atrás
            </div>
          )}
        {getType()}
      </div>
    );
  }
}

export default Processor;
