import "./Text.css"
import ok from "../images/okIcon.svg"

function Text({ question, onSubmitClick }) {
    return <div className="Text">
        {(question.text || question.subtext) &&
            <div className="Text-subtitle">
                {question.text &&
                    <p className="Text-subtitle__text" dangerouslySetInnerHTML={{__html:question.text}}></p>}
                {question.subtext &&
                    <p className="Text-subtitle__subtext" dangerouslySetInnerHTML={{__html:question.subtext}}></p>}
            </div>}

        <form className="Text-form">
            {question.button &&
                <div className="Text-submit">
                    <button onClick={onSubmitClick} className="Text-submit__button">{question.button} <img className="Text-quest__ok" src={ok} alt="" /></button>
                    <p className="Text-submit__text">pulsa <strong>Enter</strong></p>
                </div>}
        </form>
    </div>
}

export default Text; 